import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M775.766 432.333C727.671 432.333 678.661 432.333 628.766 432.333 629.999 351.008 630.333 269.658 629.766 188.333 679.328 176.334 728.004 164.332 775.766 152.333 775.766 245.657 775.766 339.009 775.766 432.333ZM577.766 432.333C530.004 432.333 481.328 432.333 432.666 432.333 432.999 367.006 433.333 301.660 432.766 236.333 482.328 224.334 531.004 212.332 579.666 200.333 579.666 202.433 579.666 206.333 579.666 209.333 579.333 283.659 578.999 358.007 577.766 432.333ZM432.666 480.433C547.321 480.433 662.011 480.433 775.766 480.433 775.766 595.988 775.766 710.678 775.766 824.433 596.684 824.433 416.648 824.433 236.666 824.433 236.999 645.684 237.333 465.982 237.666 285.433 286.328 274.334 335.004 262.332 383.666 249.433 383.999 392.985 384.333 535.681 384.666 677.433 466.324 678.000 548.007 677.666 628.766 677.333 628.766 662.001 628.766 646.665 628.766 631.333 564.006 614.335 498.326 597.331 432.666 579.433 432.666 547.336 432.666 514.330 432.666 480.433Z" fill="rgb(245, 63, 76)" p-id="1235"></path>
    </Svg>
  );
};

export default Icon;
